<template>
  <div class="home_warp">
    <!-- 顶部导航 -->
    <titleNav title="登录" :left-arrow="true" left-text="" />
    <!-- 内容 -->
    <!-- 头部背景社保小助手 -->
    <div class="topbacc"></div>
    <!-- 内容区 -->
    <div class="bodycom">
      <div class="inputfield">
        <van-field
          class="field"
          v-model="tel"
          type="number"
          maxlength="11"
          placeholder="请输入手机号"
        />
        <van-field
          class="field"
          v-model="smscode"
          center
          clearable
          placeholder="请输入验证码"
        >
          <template #button>
            <!-- <van-button size="small" type="primary">获取验证码</van-button> -->
            <div class="yanzm" v-if="isClick" @click="getlogin()">获取验证码</div>
            <div class="yanzm" v-else >重新发送（{{ countdownTime }}s）</div>
          </template>
        </van-field>
        <div class="btns" @click="loginr()">
          <van-button class="btn" round type="info" size="large"
            >注册/登录</van-button
          >
        </div>
        <van-checkbox class="checkbox" v-model="checked"
          >注册登录代表您同意
          <!-- <span @click="fuwu()">《用户协议》和</span> -->
          <span @click="yinsi()"
            >《隐私政策》</span
          ></van-checkbox
        >
      </div>
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
// import floatBtn from "@/components/floatBtn.vue";
import {
  Button,
  Dialog,
  Icon,
  Toast,
  Field,
  Checkbox,
  CheckboxGroup,
} from "vant";
import { sms, smscodecheck } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      isClick: true,
      countdownTime: 60,
      checked: false,
      tel: "",
      sms: "",
      smscode:"",
    };
  },
  created() {},
  mounted() {},

  methods: {
    yinsi(){
      this.$router.push('/textb')
    },
    fuwu(){
      this.$router.push('/textaz')
    },
    // 获取验证码
    getlogin() {
      if (this.tel == ""||this.tel.length!==11) {
        Toast("请输入正确的手机号");
      } else {
        this.isClick = false;
        this.countdown();
        
        let userid = sessionStorage.getItem("id");
        let data = {
          userid,
          phone: this.tel,
        };
        sms(data).then((res) => {
          if(res.data.code==true){
            Toast("验证码将以短信形式发送至手机")
          }else{
            Toast(res.data.data.detail[0].errmsg)
          }
        });
      }
    },
    countdown() {
      // clearInterval(timer);
      this.countdownTime = 60;
      let timer = setInterval(() => {
        if (this.countdownTime > 0) {
          this.countdownTime--;
        } else {
          this.isClick = true;
          clearInterval(timer);
        }
      }, 1000);
    },
    // 注册登录
    loginr(){
      if(this.tel == ""||this.tel.length!==11){
        Toast("请输入正确的手机号");
      }else if(this.smscode==''){
        Toast("请输入验证码");
      }else if(this.checked==false){
        Toast("请阅读并同意《隐私政策》");
      }else{
        let userid = sessionStorage.getItem("id");
        let id=1
        if(this.$route.query.id==1){
          id=1
        }else if(this.$route.query.id==2){
          id=2
        }
        let data={
          userid,
          phone:this.tel,
          sms_code:this.smscode,
          logintype:id
        }
        smscodecheck(data).then(res=>{
          if(res.data.code==true){
            localStorage.setItem("login",true)
            this.$router.go(-1)//返回上一层
          }else{
            Toast('验证码输入错误,请重新输入')
          }
        })
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped lang="scss">
.home_warp {
  height: 100%;
  background: #ffffff;
  background-color: #ffffff;
  position: relative;
  .topbacc {
    width: 100%;
    height: 220px;
    background-image: url("../../assets/images/beijin.png");
    background-size: 100% 100%;
  }
  .bodycom {
    width: 100%;
    height: 300px;
    background-color: #fff;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    position: absolute;
    top: 230px;
    .inputfield {
      width: 100%;
      box-sizing: border-box;
      padding: 50px;
      padding-bottom: 0;
      .field {
        margin-bottom: 30px;
      }
      .btns {
        text-align: center;
        //   margin-top: 20px;
        padding: 0 20px;
      }
      .checkbox {
        margin-top: 20px;
        font-size: 12px;
        span {
          color: #0097ff;
        }
      }
    }
  }
  .yanzm {
    color: #0097ff;
    border-left: 1px solid rgb(134, 134, 134);
    padding-left: 10px;
  }
}
.van-cell {
  line-height: 18px;
}
</style>
